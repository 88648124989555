import axios from "axios";
import { Input, Modal, ScrollView, Select, Text } from "native-base";
import { Avatar, Box, Button, HStack, VStack } from "native-base";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Wrapper from "../../components/Wrapper";
import { baseApi } from "../../utils/api";
import { API_URL } from "../../utils/env";
import { TouchableOpacity } from 'react-native';
import { UsuariosModal } from "../../components/UsuariosModal/UsuariosModal"

function CrearAlmacen() {
	const [form, setForm] = useState({
		name: "",
		phone: "",
		address: "",
		center_id: "",
		province_id: ""
	});
	const [provinces, setProvinces] = useState([]);
	const [delegations, setDelegations] = useState([]);
	const [userList, setUserList] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [usuarios, setUsuarios] = useState<any>([]);
	const [modalVisible, setModalVisible] = useState(false);
	const navigate = useNavigate();
	const getProvinces = async () => {
		setIsLoading(true)

		try {
			const { data } = await baseApi.get(`${API_URL}/province/all`);
			setProvinces(data);
		} catch (error) {
			if(__DEV__){
				console.log(error);
			}
		}
		setIsLoading(false)

	}
	const getDelegaciones = async () => {
		setIsLoading(true)

		try {

			const { data } = await baseApi.get(`/center/all`,{
				headers: {

					"Authorization": "Bearer " + sessionStorage.getItem("token")
				}
			});
			setDelegations(data);
		} catch (error) {
			if(__DEV__){
				console.log(error);
			}
		}
		setIsLoading(false)

	}
	const getUsuarios = async () => {
		try {
			const { data } = await baseApi.get(`${API_URL}/user/all`);
			setUserList(data);
		} catch (error) {
			if(__DEV__){
				console.log(error);
			}
		}
	};
	useEffect(() => {
		getProvinces();
		getDelegaciones();
		getUsuarios();
	}, []);
	const createCentro = async () => {
		try {
			setIsLoading(true)
			var newCentro = new FormData();
			newCentro.append("name", form.name);
			newCentro.append("phone", form.phone);
			newCentro.append("address", form.address);
			newCentro.append("center_id", form.center_id);
			newCentro.append("province_id", form.province_id);
		
			const { data } = await baseApi.post(`${API_URL}/depot/create`, form, {
				headers: {

					Authorization: "Bearer " + sessionStorage.getItem("token")
				}
			});
			
			const newIdCentro = data.id;
			usuarios.map(async (u: any) => {
				await baseApi.post(
					`${API_URL}/user/add/depot`,
					{
						"user_id": u.id,
						"depot_id": newIdCentro,
					},
					{
						headers: {	
							Authorization: "Bearer " + sessionStorage.getItem("token"),
						},
					}
				);
			});
			navigate('/almacenes');
		} catch (error) {
			if(__DEV__){
				console.log(error);
			}
		}
		setIsLoading(false)
	}
	const setUserRole = async (user: any, a= '') => {
		setIsLoading(true);
		if(usuarios.findIndex((u: any) => u.id === user.id) === -1){
			setUsuarios([...usuarios, user]);
		}
		setModalVisible(false);
		setIsLoading(false);
	};
	// function modalUsuarios() {
	// 	return (
	// 		<>
	// 			<Modal
	// 				isOpen={modalVisible}
	// 				onClose={() => setModalVisible(false)}
	// 			>
	// 				<Modal.Content>
	// 					<Modal.CloseButton />
	// 					<Modal.Header>Seleccione Usuario</Modal.Header>
	// 					<Modal.Body>
	// 						<ScrollView style={{ maxHeight: "500px" }}>
	// 							{userList.map((user: any) => {
	// 								if (user.id === 1) return false;
	// 								return (
	// 									<TouchableOpacity
	// 										onPress={() => {
	// 											setUserRole(user);
	// 										}}
	// 									>
	// 										<HStack
	// 											mb={2}
	// 											backgroundColor={"#F5F7FA"}
	// 											px={1}
	// 											borderRadius={8}
	// 											borderColor={"primary.500"}
	// 											borderWidth={1}
	// 										>
	// 											<HStack
	// 												width={"100%"}
	// 												my={2}
	// 												alignItems="center"
	// 												space={3}
	// 											>
	// 												<Avatar
	// 													size={"sm"}
	// 													bg="green.500"
	// 													source={{
	// 														uri: user.image.url,
	// 													}}
	// 												>
	// 													USER
	// 												</Avatar>
	// 												<Text
	// 													width={"100%"}
	// 													fontSize={18}
	// 													color="coolGray.600"
	// 													_dark={{
	// 														color: "warmGray.200",
	// 													}}
	// 												>
	// 													{user.username} {user.name}
	// 												</Text>
	// 											</HStack>
	// 										</HStack>
	// 									</TouchableOpacity>
	// 								);
	// 							})}
	// 						</ScrollView>
	// 					</Modal.Body>
	// 					{/* <Modal.Footer>
	// 						<Button.Group space={2}>
	// 							<Button
	// 								variant="ghost"
	// 								colorScheme="blueGray"
	// 								onPress={() => {
	// 									setModalVisible(false);
	// 								}}
	// 							>
	// 								Cancelar
	// 							</Button>
	// 							<Button
	// 								onPress={() => {
	// 									setModalVisible(false);
	// 								}}
	// 							>
	// 								Seleccionar
	// 							</Button>
	// 						</Button.Group>
	// 					</Modal.Footer> */}
	// 				</Modal.Content>
	// 			</Modal>
	// 		</>
	// 	);
	// }
	return (
		<Wrapper title="Crear Almacen" subtitle="">
			{/* {modalUsuarios()} */}
			<UsuariosModal modalVisible={modalVisible} setModalVisible={setModalVisible} setUserRole={setUserRole} modalType={'responsable'} finalRef={null} initialRef={null} />
			<div className="page-wrapper">
				<div className="page-content">
					<div className="row">
						<div className="col-xl-12 mx-auto">
							<hr />
							<div className="card">
								<div className="card-body">
									<form className="row g-3">
										<div className="col-md-4 mb-3">
											<label className="form-label">Almacén:</label>
											<Input
												value={form.name}
												onChangeText={(value)=> setForm({...form, name: value})}
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-4 mb-3">
											<label className="form-label">Telefono:</label>
											<Input
												value={form.phone}
												onChangeText={(value)=> setForm({...form, phone: value})}
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-4 mb-3">
											<label htmlFor="provincia" className="form-label">
												Provincia
											</label>
											<Select nativeID="provincia" onValueChange={(value)=> setForm({...form, province_id: value})} selectedValue={form.province_id.toString()}>
												<Select.Item value="" label="Escoger..."/>
												{provinces.map((province: any) => (
													<Select.Item key={province.id} value={province.id.toString()} label={province.name}/>
												))}
											</Select>
										</div>
										<div className="col-md-6 mb-3">
											<label className="form-label">Dirección:</label>
											<Input
												value={form.address}
												onChangeText={(value)=> setForm({...form, address: value})}
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-6 mb-3">
											<label htmlFor="center_id" className="form-label">
												Centro a Escoger
											</label>
											<Select nativeID="center_id" onValueChange={(value)=> setForm({...form, center_id: value})} selectedValue={form.center_id.toString()}>
												<Select.Item value="" label="Escoger..."/>
												{delegations.map((centers: any) => (
													<Select.Item key={centers.id} value={centers.id.toString()} label={centers.name}/>
												))}
											</Select>
										</div>
										<div className="col-md-6 mb-3">
											<HStack space={2} justifyContent={"space-between"}>
												<VStack
													textAlign={"center"}
													justifyContent={"center"}
													justifyItems={"center"}
												>
													<h5 className="form-label m-0">
														Responsables del Almacén
													</h5>
												</VStack>
												<Box justifyContent={"flex-end"}>
													<Button
														onPress={() => {
															setModalVisible(true);
														}}
														justifyContent={"flex-end"}
														variant={"ghost"}
													>
														<i className="bx font-20 bx-plus-circle"></i>
													</Button>
												</Box>
											</HStack>

											<VStack width={"100%"}>
												{usuarios.map((user: any) => (
													<HStack
														mb={2}
														backgroundColor={"#F5F7FA"}
														px={1}
														borderRadius={8}
														borderColor={"primary.500"}
														borderWidth={1}
													>
														<HStack
															width={"100%"}
															my={2}
															alignItems="center"
															space={3}
														>
															<Avatar
																size={"sm"}
																bg="green.500"
																source={{
																	uri: user.image.url,
																}}
															>
																{user.name.charAt(0)}
															</Avatar>
															<Text
																width={"100%"}
																fontSize={18}
																color="coolGray.600"
																_dark={{
																	color: "warmGray.200",
																}}
															>
																{user.name}
															</Text>
															<Button onPress={()=> {
																setUsuarios(usuarios.filter((u: any) => u.id !== user.id)
																)
															}} variant={"ghost"}>
																<i className="bx bxs-trash"></i>
															</Button>
														</HStack>
													</HStack>
												))}
											</VStack>
										</div>
										<div className="col-md-12 mb-3">
											<Button isLoading={isLoading} onPress={()=>createCentro()}>Crear Almacen</Button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
}
export default CrearAlmacen;
