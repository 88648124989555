import { baseApi, getAllReservations, apiClient } from "./api";

export const resestReservations = async () => {
    const reservation = await getAllReservations()
    const today = new Date()
    reservation.forEach((reserva: any) => {
        if ((new Date(reserva.end_date) < today) && reserva.status === 2) {
            finalizarReserva(reserva.id)
        }
    })
}

const transferUpdate = (status: number, idReserva: number) => {
    apiClient
      .put(`/reservation/update/${idReserva}`, { status })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
}

export const finalizarReserva = (id: number) => {
    apiClient
      .put(`/reservation/finalize/${id}`, {})
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
}

