import React from 'react'

function CheckIcon() {
  return (
    <svg width="30px" height="26px" viewBox="0 0 37.37875 28.49625" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Icons / icon-check</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Ficha-de-herramienta--disponible" transform="translate(-1118.749642, -174.878750)">
                <g id="Icons-/-icon-check" transform="translate(1111.503392, 163.000000)">
                    <polygon id="Shape" points="0 0 51 0 51 51 0 51"></polygon>
                    <polygon id="Shape" fill="#67C892" points="19.125 34.36125 10.26375 25.5 7.24625 28.49625 19.125 40.375 44.625 14.875 41.62875 11.87875"></polygon>
                </g>
            </g>
        </g>
    </svg>
  )
}

export default CheckIcon
