/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import axios from 'axios'
import { Avatar, Box, Button, HStack, Image, Input } from 'native-base'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Wrapper from '../../components/Wrapper'
import { API_URL } from '../../utils/env'
import Modal from '../../components/Modal/Modal'
import ShopCart from '../../utils/Persistence/ShopCart'
import EstadosRecursoEnum from '../../utils/tools/EstadosRecursoEnum'
import jwtDecode from 'jwt-decode'
import { formatDate } from '../../utils/format'
import EstadosReservasEnum from '../../utils/interfaces/EstadosReservasEnum'
import RolesEnum from '../../utils/interfaces/RolesEnum'
import { Linking, TouchableOpacity } from 'react-native'
import getEstadosReservas from '../../utils/interfaces/getEstadosReservas'

function VerReserva() {
  const navigate = useNavigate()
  const [reserva, setReserva] = useState<any>(null)
  const params = useParams()
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [centros, setCentros] = useState<any>([])
  const user: any = jwtDecode(sessionStorage.getItem('token') as string)
  const shopCart = new ShopCart()
  const token = sessionStorage.getItem('token')

  let roles = [0]
  if (token) {
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
  }
  const getReserva = () => {
    axios
      .get(`${API_URL}/reservation/${params.id}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        setReserva(response.data)
      })
      .catch((error) => {
        if (__DEV__) {
          console.log(error)
        }
      })
  }
  const getCenterUser = () => {
    axios
      .get(`${API_URL}/user/get/centers`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        setCentros(response.data)
      })
      .catch((error) => {
        if (__DEV__) {
          console.log(error)
        }
      })
  }
  const transferUpdate = (status: number) => {
    axios
      .put(
        `${API_URL}/reservation/update/${reserva.id}`,
        {
          status: status,
        },
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          },
        }
      )
      .then((response) => {
        getReserva()
      })
      .catch((error) => {
        if (__DEV__) {
          console.log(error)
        }
      })
  }
  const finalizarReserva = (id: number) => {
    axios
      .put(
        `${API_URL}/reservation/finalize/${id}`,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          },
        }
      )
      .then((response) => {
        location.reload()
      })
      .catch((error) => {
        if (__DEV__) {
          console.log(error)
        }
      })
  }
  const deleteResource = (id: number) => {
    axios
      .delete(`${API_URL}/reservation/remove/transfer/${id}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((response) => {
        getReserva()
      })
      .catch((error) => {
        if (__DEV__) {
          console.log(error)
        }
      })
  }
  useEffect(() => {
    getReserva()
    getCenterUser()
  }, [])
  if (!reserva) return <></>
  return (
    <Wrapper title={`Cesta de Reserva ${params.id}`} subtitle=''>
      {/* <!--start page wrapper --> */}
      <div className='page-wrapper' style={{ height: '100vh' }}>
        <div className='page-content'>
          <div className='row'>
            <div className='col-xl-12 mx-auto'>
              <h6 className='mb-0 text-uppercase'></h6>
              {(roles.includes(RolesEnum.Docente) ||
                roles.includes(RolesEnum.Admin) ||
                roles.includes(RolesEnum.CoordinadorCentro)
              ) 
                && (
                <>
                  {reserva.status === 2 && (
                    <Box flexDir={'row'} justifyContent={'flex-end'}>
                      {/* <Input width={200} mr={4} placeholder={'Buscar...'} /> */}
                      <Button
                        colorScheme={'danger'}
                        onPress={() => {
                          const confirmar = confirm(
                            '¿Está seguro de finalizar esta reserva?'
                          )
                          if (confirmar) {
                            finalizarReserva(reserva?.id)
                          }
                        }}
                      >
                        Finalizar Reserva
                      </Button>
                    </Box>
                  )}
                </>
              )}
              <hr />
              <div className='card'>
                <div className='card-body'>
                  <table className='table table-responsive'>
                    <thead className='bg-dark text-white'>
                      <tr>
                        <th>Id</th>
                        <th>Reservado por</th>
                        <th>Fecha Solicitud</th>
                        <th>Fecha Reserva</th>
                        <th>Finalización</th>
                        <th>Recursos</th>
                        <th>Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{reserva?.id}</td>
                        <td>
                          <HStack space={2}>
                            <Avatar
                              source={{ uri: reserva.user.image.url }}
                              size={'sm'}
                            >
                              {reserva.user.username.charAt(0) +
                                reserva.user.name.charAt(0)}
                            </Avatar>
                            <label className='form-label font-18  mb-0'>
                              {reserva.user.username} {reserva.user.name}
                            </label>
                          </HStack>
                        </td>
                        <td>{formatDate(new Date(reserva.created_at))}</td>
                        <td>{formatDate(new Date(reserva.start_date))}</td>
                        <td>{formatDate(new Date(reserva.end_date))}</td>
                        <td>{reserva.transfers.length}</td>
                        <td>{getEstadosReservas(reserva.status)}</td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                  {reserva.status === 1 &&
                  (roles.includes(RolesEnum.CoordinadorCentro) ||
                    roles.includes(RolesEnum.Admin)) ? (
                    <>
                      <div className='row'>
                        <div className='col-md-2'>
                          <Button
                            onPress={() => {
                              const confirmar = confirm(
                                '¿Está seguro de aceptar esta reserva?'
                              )
                              if (confirmar) {
                                transferUpdate(EstadosReservasEnum.Aprobado)
                              }
                            }}
                            colorScheme={'green'}
                          >
                            Aceptar
                          </Button>
                        </div>
                        <div className='col-md-2'>
                          <Button
                            onPress={() => {
                              const confirmar = confirm(
                                '¿Está seguro de rechazar esta reserva?'
                              )
                              if (confirmar) {
                                transferUpdate(EstadosReservasEnum.Rechazado)
                              }
                            }}
                            colorScheme={'danger'}
                          >
                            Rechazar
                          </Button>
                        </div>
                      </div>
                      <hr />
                    </>
                  ) : null}
                  <table className='table table-responsive'>
                    <thead className='bg-primary text-white'>
                      <tr>
                        <th></th>
                        <th>Id</th>
                        <th>Nombre</th>
                        <th>Familia</th>
                        <th>Modelo</th>
                        {/* <th>Almacen</th> */}
                        <th>Estado</th>
                        <th></th>
                        {reserva.status === 1 &&
                        roles.includes(RolesEnum.CoordinadorCentro) ? (
                          <th></th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {reserva?.transfers.map((recurso: any) => (
                        <>
                          <tr>
                            <td>
                              <Image
                                alt='logo'
                                width={35}
                                height={35}
                                source={{
                                  uri: recurso.resource.image
                                    ? recurso.resource.image.url
                                    : '',
                                }}
                              />
                            </td>
                            <td>{recurso.resource.id}</td>
                            <td>{recurso.resource.name}</td>
                            <td>{recurso.resource.fabricator}</td>
                            <td>{recurso.resource.model}</td>
                            {/* <td>{recurso?.depot?.name}</td> */}
                            <td>
                              <span className='font-18 text-primary fw-bold'>
                                {EstadosRecursoEnum[recurso.resource.status]}
                              </span>
                            </td>
                            <td>
                              <div>
                                <a
                                  // maxWidth={140}
                                  // p={2}
                                  // colorScheme={`warning`}
                                  href={`/recursos/ver/${recurso.resource.id}`}
                                  // size={'xs'}
                                  className='font-18 btn btn-sm btn-warning w-100'
                                >
                                  Ver
                                </a>
                              </div>
                            </td>
                            {reserva.status === 1 &&
                            roles.includes(RolesEnum.CoordinadorCentro) ? (
                              <td>
                                <Button
                                  // maxWidth={140}
                                  p={2}
                                  colorScheme={`red`}
                                  onPress={async () => {
                                    const confirmar = confirm(
                                      '¿Está seguro de eliminar este recurso?'
                                    )
                                    if (confirmar) {
                                      deleteResource(recurso.id)
                                    }
                                  }}
                                  size={'xs'}
                                  className='btn btn-xs '
                                >
                                  <i
                                    className={`bx ${'bx-trash'} text-white font-14`}
                                  />
                                </Button>
                              </td>
                            ) : null}
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                  <hr />
                  <h5 className='text-danger'>
                    Con Incidencia - No se puede usar hasta que no este reparada
                  </h5>
                  <table className='table table-responsive'>
                    <thead className='bg-danger text-white'>
                      <tr>
                        <th></th>
                        <th>Id</th>
                        <th>Nombre</th>
                        <th>Familia</th>
                        <th>Modelo</th>
                        {/* <th>Almacen</th> */}
                        <th>Estado</th>
                        <th></th>
                        {reserva.status === 1 &&
                        roles.includes(RolesEnum.CoordinadorCentro) ? (
                          <th></th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {reserva?.issues.map((recurso: any) => (
                        <>
                          <tr>
                            <td>
                              <Image
                                alt='logo'
                                width={35}
                                height={35}
                                source={{
                                  uri: recurso.resource.image
                                    ? recurso.resource.image.url
                                    : '',
                                }}
                              />
                            </td>
                            <td>{recurso.resource.id}</td>
                            <td>{recurso.resource.name}</td>
                            <td>{recurso.resource.fabricator}</td>
                            <td>{recurso.resource.model}</td>
                            {/* <td>{recurso?.depot?.name}</td> */}
                            <td>
                              <span className='font-18 text-primary fw-bold'>
                                {EstadosRecursoEnum[recurso.resource.status]}
                              </span>
                            </td>
                            <td>
                              <div>
                                <a
                                  // maxWidth={140}
                                  // p={2}
                                  // colorScheme={`warning`}
                                  href={`/recursos/ver/${recurso.resource.id}`}
                                  // size={'xs'}
                                  className='font-18 btn btn-sm btn-warning w-100'
                                >
                                  Ver
                                </a>
                              </div>
                            </td>
                            {reserva.status === 1 &&
                            roles.includes(RolesEnum.CoordinadorCentro) ? (
                              <td>
                                <Button
                                  // maxWidth={140}
                                  p={2}
                                  colorScheme={`red`}
                                  onPress={async () => {
                                    const confirmar = confirm(
                                      '¿Está seguro de eliminar este recurso?'
                                    )
                                    if (confirmar) {
                                      deleteResource(recurso.id)
                                    }
                                  }}
                                  size={'xs'}
                                  className='btn btn-xs '
                                >
                                  <i
                                    className={`bx ${'bx-trash'} text-white font-14`}
                                  />
                                </Button>
                              </td>
                            ) : null}
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--end page wrapper --> */}
    </Wrapper>
  )
}

export default VerReserva
