/* eslint-disable no-script-url */
import { Button, Select, useTheme } from 'native-base'
import Wrapper from '../../components/Wrapper'
import jwtDecode from 'jwt-decode'
import RolesEnum from '../../utils/interfaces/RolesEnum'
import { useEffect, useState } from 'react'
import { baseApi } from '../../utils/api'
import { API_URL } from '../../utils/env'

function SubirRecursos() {
  const { colors } = useTheme()
  const [images, setImages] = useState<any>([])
  const token = sessionStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [depot_id, setDepot_id] = useState<any>('')
  const [delegations, setDelegations] = useState<any>([])
  let roles = [0]
  if (token) {
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
  }
  const getDelegaciones = async () => {
    let urll = '/user/get/depots'

    if (roles.includes(RolesEnum.Admin)) {
      urll = `/depot/all`
    }
    try {
      setLoading(true)
      const { data } = await baseApi.get(`${urll}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      setDelegations(data)
    } catch (error) {
      if(__DEV__){
				  console.log(error);
			  }
    } finally {
      setLoading(false)
    }
  }
  const uploadFile = async () => {
    if(images.length === 0){
      alert('Debe seleccionar un archivo')
      return
    }
    if(depot_id === ''){
      alert('Debe seleccionar un almacén')
      return
    }

    try {
      setLoading(true)
      var newForm = new FormData()
      newForm.append('depot_id', depot_id ?? '')

      if(images.length > 0){
        newForm.append("file", images[0]!, images[0] ? images[0].name : "");
      }

      await baseApi.post(
        `${API_URL}/resource/import/xlsx`,
        newForm,
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      alert('Archivo cargado correctamente')
    } catch (error) {
      if(__DEV__){
				  console.log(error);
			  }
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getDelegaciones()
  }, [])
  return (
    <Wrapper title='Subir Recursos Masivos' subtitle=''>
      {/* <!--start page wrapper --> */}
      <div className='page-wrapper'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-12'>
              <div className='card radius-5'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-md-6 mb-3'>
                      <label className='form-label'>
                        Subir archivo a cargar:
                      </label>
                      <input
                        onChange={(event: any) => {
                          if (images.length < 4) {
                            setImages([...images, event.target.files[0]])
                          }
                        }}
                        type='file'
                        className='form-control'
                      />
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label htmlFor='center_id' className='form-label'>
                        Almacén:
                      </label>
                      <Select
                        nativeID='center_id'
                        onValueChange={(value) => setDepot_id(value)}
                        selectedValue={depot_id.toString()}
                      >
                        <Select.Item value='' label='Escoger...' />
                        {delegations.map((centers: any) => (
                          <Select.Item
                            key={centers.id}
                            value={centers.id.toString()}
                            label={centers.name}
                          />
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="row">
                    <div className='col-md-3  mb-3'>
                      <Button
                        color={'success.400'}
                        isLoading={loading}
                        onPress={() => uploadFile()}
                      >
                        Guardar
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--end page wrapper --> */}
    </Wrapper>
  )
}

export default SubirRecursos
