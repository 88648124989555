/* eslint-disable no-script-url */
import { Button, useTheme } from 'native-base'
import Wrapper from '../../components/Wrapper'
import jwtDecode from 'jwt-decode'
import { useEffect, useState } from 'react'
import SelectSearch from 'react-select-search'
import './react-style.css'
import { baseApi } from '../../utils/api'
import RolesEnum from '../../utils/interfaces/RolesEnum'
import { useParams } from 'react-router-dom'

function LogsIncidencias() {
  const { colors } = useTheme()
  const params = useParams()

  const [form, setForm] = useState({
    from: '',
    to: '',
  })
  const token = sessionStorage.getItem('token')
  let roles = [0]
  if (token) {
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
  }
  const [setValue, setSetValue] = useState<any>('')
  const [options, setOptions] = useState<any>([])
  const getOptions = async () => {
    try {
      const url = roles.includes(RolesEnum.Admin)
        ? `/resource/all?`
        : `/user/get/resources?`
      let newData: any = []
      const { data } = await baseApi.get(`${url}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      newData.push({ name: 'TODOS', value: '0' })
      // eslint-disable-next-line array-callback-return
      data.map((v: any) => {
        newData.push({ name: v.name, value: v.id })
      })
      setOptions(newData)
    } catch (error) {
      console.log('🚀 ~ getOptions ~ error:', error)
    }
  }
  function downloadXLSX(base64: string) {
    const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`
    const downloadLink = document.createElement('a')
    const fileName = 'exportLogs.xlsx'

    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }
  const exportLogs = async () => {
    try {
      const url = `/resource-issue/logs/${params.id}`
      console.log('🚀 ~ exportLogs ~ form:', form)
      if (form.from === '' || form.to === '') {
        alert('Por favor seleccione un rango de fechas')
        return
      }
      let paramss: any = {
        start: form.from,
        end: form.to,
      }
      const { data } = await baseApi.get(`${url}`, {
        params: paramss,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      if (data.statusCode === 200) {
        downloadXLSX(data.data)
      }
    } catch (error) {
      console.log('🚀 ~ getOptions ~ error:', error)
    }
  }
  useEffect(() => {
    getOptions()
  }, [])

  return (
    <Wrapper title='Resumen'>
      {/* <!--start page wrapper --> */}
      <div className='page-wrapper'>
        <div className='page-content'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title text-primary'>
                Exportar Historial Incidencia
              </h4>
              <div className='row gap-3 py-3'>
                <div className='col-12 col-md-5'>
                  <label className='fw-bold mb-1'>Fecha desde:</label>
                  <input
                    onChange={(value) => {
                      setForm({
                        ...form,
                        from: value.target.value,
                      })
                    }}
                    type='date'
                    className='form-control'
                    placeholder=''
                  />
                </div>
                <div className='col-12 col-md-5'>
                  <label className='fw-bold mb-1'>Fecha hasta:</label>
                  <input
                    onChange={(value) => {
                      setForm({
                        ...form,
                        to: value.target.value,
                      })
                    }}
                    type='date'
                    className='form-control'
                    placeholder=''
                  />
                </div>
                {/* <div className='col-12 col-md-5'>
                  <label className='fw-bold mb-1'>Recursos:</label>
                  <SelectSearch
                    options={options}
                    search={true}
                    value={setValue}
                    defaultValue={'0'}
                    onChange={(value) => {
                      setSetValue(value)
                    }}
                    placeholder='Seleccionar recurso'
                  />
                </div> */}

                {/* <div className='col-md-3'>
                      <label className='fw-bold mb-1'>
                        Fecha de utilización:
                      </label>
                      <input onChange={(event: any)=>{
												setStartDate(event.target.value);
											}} className='form-control' type='date' />
                    </div>
                    <div className='col-md-3'>
                      <label className='fw-bold mb-1'>
                        Fecha de finalización:
                      </label>
                      <input onChange={(event: any)=>{
												setEndDate(event.target.value);
											}} className='form-control' type='date' />
                    </div> */}
                <div className='col-12 col-md-3'>
                  <label className='fw-bold mb-1'></label>
                  <Button onPress={() => exportLogs()}>Buscar</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--end page wrapper --> */}
    </Wrapper>
  )
}

export default LogsIncidencias
