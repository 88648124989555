/* eslint-disable no-script-url */
import { useTheme } from 'native-base'
import Wrapper from '../../components/Wrapper'
import jwtDecode from 'jwt-decode'
import RolesEnum from '../../utils/interfaces/RolesEnum'
import Chart from 'react-google-charts'
import { useEffect, useState } from 'react'
import { baseApi } from '../../utils/api'
import { resestReservations } from '../../utils/multiple'
import { randomColor } from 'native-base/lib/typescript/theme/tools'

function HomeResp() {
  const { colors } = useTheme()
  const [dataCenters, setDataCenters] = useState<any>([])
  const [dataUsed, setDataUsed] = useState<any>([])
  const getDataCenters = async () => {
    try {
      const { data } = await baseApi.get(`/resource/get/statistics/4`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      setDataCenters(data)
    } catch (error) {
      if(__DEV__){
				console.log(error);
			}
    }
  }
  const getDataUsed = async () => {
    try {
      const { data } = await baseApi.get(`/resource/get/statistics/1`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      setDataUsed(data)
    } catch (error) {
      if(__DEV__){
				console.log(error);
			}
    }
  }
  useEffect(() => {
    getDataCenters()
    getDataUsed()
    resestReservations()
  },[])
  const token = sessionStorage.getItem('token')
  let roles = [0]
  if (token) {
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
  }
  return (
    <Wrapper>
      {/* <!--start page wrapper --> */}
      <div className='page-wrapper'>
        <div className='page-content'>
          {roles.includes(RolesEnum.ResponsableAlmacen) ? (
            <div className='row'>
              <div className='col-12 col-lg-6 d-flex'>
                <div className='card radius-10 w-100'>
                  <div className='card-body'>
                    <div className='d-flex align-items-center'>
                      <div>
                        <h6
                          className='mb-0'
                          style={{ color: colors.primary[500] }}
                        >
                          Recursos en uso en centros de formación:
                        </h6>
                      </div>
                    </div>
                    <ul
                      className='list-group list-group-flush'
                      style={{ marginTop: '10px' }}
                    >
                      <li className='list-group-item d-flex bg-transparent justify-content-between align-items-center '>
                        Recursos totales registrados:{' '}
                        <span className='badge text-black rounded-pill'>
                          {dataUsed.total}
                        </span>
                      </li>
                      <li className='list-group-item d-flex bg-transparent justify-content-between align-items-center'>
                        Recursos en uso:{' '}
                        <span className='badge text-black rounded-pill'>
                          {dataUsed.uso}
                        </span>
                      </li>
                      <li className='list-group-item d-flex bg-transparent justify-content-between align-items-center'>
                        Recursos disponibles:{' '}
                        <span className='badge text-black rounded-pill'>
                          {dataUsed.disponible}
                        </span>
                      </li>
                      <li className='list-group-item d-flex bg-transparent justify-content-between align-items-center'>
                        Incidencias actuales:{' '}
                        <span className='badge text-black rounded-pill'>
                          {dataUsed.incidencia}
                        </span>
                      </li>
                      <li className='list-group-item d-flex bg-transparent justify-content-between align-items-center'>
                        Recursos pendientes de revisión:{' '}
                        <span className='badge text-black rounded-pill'>
                          {dataUsed.revision}
                        </span>
                      </li>
                      <li className='list-group-item d-flex bg-transparent justify-content-between align-items-center'>
                        Recursos pendientes de devolución:{' '}
                        <span className='badge text-black rounded-pill'>
                          {dataUsed.devolucion}
                        </span>
                      </li>
                      <li className='list-group-item d-flex bg-transparent justify-content-between align-items-center'>
                        Herramienta en traslado:{' '}
                        <span className='badge text-black rounded-pill'>
                          {dataUsed.traslado}
                        </span>
                      </li>
                      <li className='list-group-item d-flex bg-transparent justify-content-between align-items-center'>
                        Recursos dados de baja:{' '}
                        <span className='badge text-black rounded-pill'>
                          {dataUsed.baja}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-12 col-lg-6 d-flex'>
                <div className='card radius-10 w-100'>
                  <div className='card-body'>
                    <div className='d-flex align-items-center'>
                      <div>
                        <h6
                          className='mb-0'
                          style={{ color: colors.primary[500] }}
                        >
                          Reserva por mes:
                        </h6>
                      </div>
                    </div>
                    <div className='chart-container-13 mt-3'>
                      {/* <canvas id='chart2'></canvas> */}
                      <Chart
      chartType="PieChart"
      data={[["Fecha", "Total"],...dataCenters.map((v: any) => [v.date, parseInt(v.total)])]}
      options={{legend: {position: 'bottom', alignment: 'center', text: 'a' },pieHole: 1, is3D: true, pieSliceText: 'value',tooltip: { trigger: 'selection' }}}
      width={"100%"}
      height={"100%"}
      legendToggle
    />
                    </div>
                  </div>
                  <ul className='list-group list-group-flush'>
                  {dataCenters.map((v: any)=>{
                    if(v.total == 0)return null;
                    return (
                    <li className='list-group-item d-flex bg-transparent justify-content-between align-items-center border-top'>
                      {v.date}{' '}
                      <span className='badge text-dark rounded-pill'>{v.total}</span>
                    </li>
                    )
                  })}
                    
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
          {/* <!--end row--> */}
        </div>
      </div>
      {/* <!--end page wrapper --> */}
    </Wrapper>
  )
}

export default HomeResp
