function IncidenciaIcon() {
  return (
    <svg
      width='41.1666667px'
      height='34.6666667px'
      viewBox='0 0 41.1666667 34.6666667'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Icons / icon-incident</title>
      <defs>
        <polygon
          id='path-1'
          points='0 0 41.1666667 0 41.1666667 34.6666667 0 34.6666667'
        ></polygon>
      </defs>
      <g
        id='Page-1'
        stroke='none'
        stroke-width='1'
        fill='none'
        fill-rule='evenodd'
      >
        <g
          id='Ficha-de-herramienta--disponible-Copy-7'
          transform='translate(-1114.333333, -165.500000)'
        >
          <g
            id='Icons-/-icon-incident'
            transform='translate(1114.333333, 165.500000)'
          >
            <g id='Group-3' transform='translate(-0.000000, 0.000000)'>
              <mask id='mask-2' fill='white'>
                <use xlinkHref='#path-1'></use>
              </mask>
              <g id='Clip-2'></g>
              <path
                d='M18.7439059,29.1763582 L22.3908883,29.1763582 L22.3908883,25.6475253 L18.7439059,25.6475253 L18.7439059,29.1763582 Z M18.7477692,21.8675757 L22.415678,21.8675757 L22.415678,14.6797943 L18.7477692,14.6797943 L18.7477692,21.8675757 Z M41.1666667,34.6666667 L0,34.6666667 C6.87221818,23.09173 13.6803695,11.6249086 20.5822065,0 C27.4676244,11.5963083 34.2838243,23.0750727 41.1666667,34.6666667 L41.1666667,34.6666667 Z'
                id='Fill-1'
                fill='#F62B2B'
                mask='url(#mask-2)'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IncidenciaIcon