const getStatusRecursoDocument = (status: string) => {
  switch (status) {
    case "reviewed":
      return <i className='bx bx-check text-success bx-sm'></i>;
    case "pending_review":
      return <i className='bx bx-alarm-exclamation text-warning bx-sm'></i>;
    default:
      return <i className='bx bx-check bx-sm'></i>;
  }
}

export default getStatusRecursoDocument;