/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import axios from 'axios'
import { Avatar, Box, Button, Input } from 'native-base'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Wrapper from '../../components/Wrapper'
import { API_URL } from '../../utils/env'
import { UserInterface } from '../../utils/interfaces/UserInterface'
import { TouchableOpacity } from 'react-native'
import jwtDecode from 'jwt-decode'
import RolesEnum from '../../utils/interfaces/RolesEnum'

function Usuarios() {
  const navigate = useNavigate()
  const [usersList, setUsersList] = useState([])
  const [search, setSearch] = useState('')
  const token = sessionStorage.getItem('token')
  let roles = [0]
  if (token) {
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
  }
  const getUsers = () => {
    axios
      .get(`${API_URL}/user/all?search=${search}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then((res) => {
        setUsersList(res.data)
      })
      .catch((err) => {
        if(__DEV__){
				  console.log(err);
			  }
      })
  }
	const deleteUser = (id: number) => {
		axios
			.delete(`${API_URL}/auth/delete/${id}`, {
				headers: {
					Authorization: "Bearer " + sessionStorage.getItem("token"),
				},
			})
			.then((res) => {
				alert("Usuario eliminado correctamente");
				getUsers();
			})
			.catch((err) => {
				if(__DEV__){
				  console.log(err);
			  }
			});
	}
  useEffect(() => {
    getUsers()
  }, [])
  useEffect(() => {
    getUsers()
  }, [search])
  return (
    <Wrapper title='Usuarios' subtitle=''>
      {/* <!--start page wrapper --> */}
      <div className='page-wrapper'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-xl-12 mx-auto'>
              {/* <h6 className="mb-0 text-uppercase"></h6> */}
              <Box flexDir={'row'} justifyContent={'flex-end'}>
                <Input
                  onChangeText={(e) => {
                    setSearch(e)
                  }}
                  width={200}
                  mr={4}
                  placeholder={'Buscar...'}
                />
                <Button onPress={() => navigate('/usuarios/crear')}>
                  Crear{' '}
                </Button>
              </Box>
              <hr />
              <div className='card'>
                <div className='card-body table-responsive'>
                  <table className='table mb-0 table-reponsive'>
                    <thead>
                      <tr>
                        <th scope='col'>ID</th>
                        <th scope='col'></th>
                        <th scope='col'>Nombres</th>
                        <th scope='col'>Apellidos</th>
                        <th scope='col'>Email</th>
                        <th scope='col'>S. Admin</th>
                        <th scope='col'>Estado</th>
                        <th scope='col'></th>
                        {roles.includes(RolesEnum.Admin) && (
                          <th scope='col'></th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {usersList.map((user: UserInterface) => (
                        <tr>
                          <td>{user.id}</td>
                          <td>
                            <Avatar
                              size={'sm'}
                              bg='green.500'
                              source={{
                                uri: user.image.url,
                              }}
                            >
                              {user.name.charAt(0)}
                            </Avatar>
                          </td>
                          <td>{user.username}</td>
                          <td>{user.name}</td>
                          <th scope='row'>{user.email}</th>
                          <td>
                            {user.userRole[0].role_id === 1 ? 'SI' : 'NO'}
                          </td>
                          <td>{user.active ? 'Activo' : 'No Activo'}</td>
                          <td>
                            <TouchableOpacity
                              onPress={() =>
                                navigate(`/usuarios/editar/${user.id}`)
                              }
                            >
                              <i className='bx bx-edit font-24 '></i>
                            </TouchableOpacity>
                          </td>
                          {roles.includes(RolesEnum.Admin) && <td>
														<TouchableOpacity onPress={()=>{
															const confirm = window.confirm("¿Está seguro que desea eliminar este usuario?");
															if(confirm){
																deleteUser(user.id)
															}
														}}>
															<i className="bx bx-trash font-24" ></i>
														</TouchableOpacity>
													</td>}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--end page wrapper --> */}
    </Wrapper>
  )
}

export default Usuarios
