/* eslint-disable no-script-url */
import { Box } from "native-base"
import Header from "./Header/Header";
import MenuLeft from "./MenuLeft";

function Wrapper({ children, hasButtonRight, title, subtitle }: { hasButtonRight?: boolean, title?: string, subtitle?: string, children: React.ReactNode }) {
	return (
		<>
			{/* <!--wrapper--> */}
			<div className="wrapper">
					<MenuLeft />
					<Header hasButtonRight={hasButtonRight} title={title} subtitle={subtitle} />
				{children}
				<footer className="page-footer">
					<p className="mb-0">
						Copyright © {new Date().getFullYear()}. All right reserved.
					</p>
				</footer>
                {/* <!--start overlay--> */}
			<div className="overlay toggle-icon"></div>
			{/* <!--end overlay--> */}
			{/* <!--Start Back To Top Button--> */}
			<a href="#!" className="back-to-top">
				<i className="bx bxs-up-arrow-alt"></i>
			</a>
			{/* <!--End Back To Top Button--> */}
			</div>
			{/* <!--end wrapper--> */}
			
		</>
	);
}
export default Wrapper;
