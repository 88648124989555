/* eslint-disable no-restricted-globals */
import axios from 'axios'
import { Image, Input, Modal, ScrollView, Select, Text } from 'native-base'
import { Avatar, Box, Button, HStack, VStack } from 'native-base'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Wrapper from '../../components/Wrapper'
import { baseApi } from '../../utils/api'
import { API_URL } from '../../utils/env'
import { Linking, TouchableOpacity } from 'react-native'
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import PdfIcon from '../../components/Icons/PdfIcon'
import getStatusRecursoDocument from './utils/getStatusRecursoDocument'
import { formatDate, formatDateEng } from '../../utils/format'
import jwtDecode from 'jwt-decode'
import RolesEnum from '../../utils/interfaces/RolesEnum'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import getTranslateDataType from './utils/getTranslateDataType'

function EditarRecursos() {
  const [form, setForm] = useState<any>({
    name: '',
    cime: '',
    cime_date: '',
    fabricator: '',
    model: '',
    acquisition_date: '',
    serie: '',
    year: '',
    weight: '',
    depot_id: '',
    observations: '',
  })
  const [formDocAsoc, setFormDocAsoc] = useState<any>({
    name: '',
    document_type_id: '',
    resource_id: '',
    file: null,
    period: '',
    review_date: '',
    responsible: '',
  })
  const params = useParams()
  const [newId, setNewId] = useState<any>(params.id)
  const [images, setImages] = useState<any>([])
  const [imagesRes, setImagesRes] = useState<any>([])
  const [imagesResExt, setImagesResExt] = useState<any>([])
  const [documentType, setDocumentType] = useState([])
  const [delegations, setDelegations] = useState([])
  const [dataTable, setDataTable] = useState<any[]>([])
  const [userList, setUserList] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [usuarios, setUsuarios] = useState<any>([])
  const [modalVisible, setModalVisible] = useState(false)
  const [resourcesTypes, setResourcesTypes] = useState<any>([])
  const columnHelper = createColumnHelper<any>()
  const token = sessionStorage.getItem('token')
  let roles = [0]
  if (token) {
    const { userData }: any = jwtDecode(token as string)
    roles = userData.userRole.map((v: any) => v.role_id)
  }
  const [modeEdit, setModeEdit] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [imagesSec, setImagesSec] = useState<any>([])
  const [resourceId] = useState<any>(params.id)
  const columns: any[] = [
    columnHelper.accessor((row) => row.id, {
      id: 'id',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <span className='center'>ID</span>,
      footer: () => <span>ID</span>,
    }),
    columnHelper.accessor((row) => row.type, {
      id: 'Tipo',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.name, {
      id: 'Nombre',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.period, {
      id: 'Periodo',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.date_next_review, {
      id: 'Fecha próxima revisión',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.date_last_review, {
      id: 'Fecha última revisión',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.responsable, {
      id: 'Responsable',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.document, {
      id: 'Documento',
      cell: (info) => <i>{info.getValue()}</i>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.actions, {
      id: 'actions',
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <span></span>,
      footer: () => <span></span>,
    }),
  ]
  const data: any[] = [
    // {
    //   id: '10',
    //   type: data.documentType.name,
    //   name: 'Nombre Documento',
    //   period: '12 meses',
    //   date_next_review: '15/02/2023',
    //   responsable: 'Almacén',
    //   document: (
    //     <TouchableOpacity style={{ alignItems: 'center' }}>
    //       <PdfIcon />
    //     </TouchableOpacity>
    //   ),
    //   actions: (
    //     <div className='d-flex'>
    //       <TouchableOpacity style={{ paddingHorizontal: 1 }}>
    //         <i className='bx bx-pencil bx-sm'></i>
    //       </TouchableOpacity>
    //       <TouchableOpacity style={{ paddingHorizontal: 1 }}>
    //         <i className='bx bx-trash bx-sm'></i>
    //       </TouchableOpacity>
    //     </div>
    //   ),
    // },
  ]
  const navigate = useNavigate()
  const getDelegaciones = async () => {
    setIsLoading(true)
    let urll = '/user/get/depots'

    if (roles.includes(RolesEnum.Admin)) {
      urll = `/depot/all`
    }
    try {
      const { data } = await baseApi.get(`${urll}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      setDelegations(data)
    } catch (error) {
      if (__DEV__) {
        console.log(error)
      }
    }
    setIsLoading(false)
  }
  const getDocumentType = async () => {
    setIsLoading(true)

    try {
      const { data } = await baseApi.get(`/document-type/all`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      setDocumentType(data)
    } catch (error) {
      if (__DEV__) {
        console.log(error)
      }
    }
    setIsLoading(false)
  }
  const removeResDoc = async (id: any) => {
    try {
      // eslint-disable-next-line no-restricted-globals
      const confirmm = confirm('¿Está seguro de eliminar este documento?')
      if (!confirmm) return
      await baseApi.delete(`${API_URL}/resource-document/delete/${id}`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      getResource()
    } catch (error) {
      if (__DEV__) {
        console.log(error)
      }
    }
  }
  const getResourceType = async () => {
    setIsLoading(true)

    try {
      const { data } = await baseApi.get(`/resource-type/all`, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      const newData = getTranslateDataType(data)
      setResourcesTypes(newData)
    } catch (error) {
      if (__DEV__) {
        console.log(error)
      }
    }
    setIsLoading(false)
  }
  const getResource = async () => {
    try {
      setLoading(true)
      const { data: dataD } = await baseApi.get(
        `${API_URL}/resource/${resourceId}`,
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          },
        }
      )
      setForm(dataD)
      setImagesRes([dataD.image])
      setImagesResExt(dataD.resourceImage)
      let datatableall: any[] = []

      dataD.resourceDocument.map((data: any) => {
        let responsible = ''
        switch (parseInt(data.responsible)) {
          case RolesEnum.CoordinadorCentro:
            responsible = 'Coordinador'
            break
          case RolesEnum.ResponsableAlmacen:
            responsible = 'Almacén'
            break
          default:
            break
        }
        datatableall = [
          ...datatableall,
          {
            expander: '',
            id: data.id,
            type: data.documentType.name,
            name: data.name,
            period: `${data.period ? data.period : ''}`,
            date_last_review: data.period
              ? formatDate(new Date(data.review_date))
              : '',
            date_next_review: data.period
              ? formatDate(new Date(data.next_review_date))
              : '',
            responsable: responsible,
            document: (
              <TouchableOpacity
                onPress={() => Linking.openURL(data.file.url)}
                style={{ alignItems: 'center' }}
              >
                <PdfIcon />
              </TouchableOpacity>
            ),
            status: getStatusRecursoDocument(data.status),
            actions: (
              <div className='d-flex'>
                <TouchableOpacity
                  onPress={() => {
                    removeResDoc(data.id)
                  }}
                  style={{ paddingHorizontal: 1 }}
                >
                  <i className='bx bx-trash bx-sm'></i>
                </TouchableOpacity>
              </div>
            ),
            data: data,
          },
        ]
        return false
      })
      setDataTable(datatableall)
    } catch (error) {
      if (__DEV__) {
        console.log(error)
      }
    }
    setLoading(false)
  }
  useEffect(() => {
    getResource()
    getDelegaciones()
    getDocumentType()
    getResourceType()
  }, [])
  const createCentro = async () => {
    try {
      setIsLoading(true)
      var newForm = new FormData()
      newForm.append('name', form.name ?? '')
      newForm.append('cime', form.cime ?? '')
      newForm.append('cime_date', form.cime_date ?? '')
      newForm.append('fabricator', form.fabricator ?? '')
      newForm.append('model', form.model ?? '')
      newForm.append('serie', form.serie ?? '')
      newForm.append('year', form.year ?? '')
      newForm.append('acquisition_date', form.acquisition_date ?? '')
      newForm.append('weight', form.weight ?? '')
      newForm.append('depot_id', form.depot_id ?? '')
      newForm.append('observations', form.observations ?? '')
      if (images.length > 0) {
        newForm.append('image', images[0]!, images[0] ? images[0].name : '')
      }

      await baseApi.put(`${API_URL}/resource/update/${resourceId}`, newForm, {
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          'Content-Type': 'multipart/form-data',
        },
      })

      imagesSec.map(async (image: any) => {
        var formTemp = new FormData()

        formTemp.append('image', image!, images ? images.name : '')
        await baseApi.post(
          `${API_URL}/resource/${resourceId}/add-image`,
          formTemp,
          {
            headers: {
              Authorization: 'Bearer ' + sessionStorage.getItem('token'),
              'Content-Type': 'multipart/form-data',
            },
          }
        )
      })
      // window.location.reload()
    } catch (error) {
      if (__DEV__) {
        console.log(error)
      }
    }
    setIsLoading(false)
  }
  const createDoc = async () => {
    try {
      // if(!formDocAsoc.file) return alert('Debe seleccionar un archivo')
      if (formDocAsoc.period !== '' && formDocAsoc.review_date === '')
        return alert(
          'Si selecciona un periodo debe seleccionar una fecha de revisión'
        )
      if (formDocAsoc.period === '' && formDocAsoc.review_date !== '')
        return alert(
          'Si selecciona un fecha de revisión debe seleccionar un periodo.'
        )
      if (!formDocAsoc.file) return alert('Debe seleccionar un archivo')
      setIsLoading(true)
      var newForm = new FormData()
      newForm.append('name', formDocAsoc.name)
      newForm.append('document_type_id', formDocAsoc.document_type_id)
      newForm.append(
        'resource_id',
        !!newId ? (newId! as number).toString() : '0'
      )
      newForm.append('period', formDocAsoc.period)
      newForm.append('review_date', formDocAsoc.review_date)
      newForm.append('responsible', formDocAsoc.responsible)
      if (!!formDocAsoc.file) {
        newForm.append(
          'file',
          formDocAsoc.file!,
          formDocAsoc.file ? formDocAsoc.file.name : ''
        )
      }

      const { data } = await baseApi.post(
        `${API_URL}/resource-document/create`,
        newForm,
        {
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      let datatableall: any[] = []
      datatableall = [
        ...datatableall,
        {
          id: data.id,
          type: '',
          name: data.name,
          period: `${data.period}`,
          date_next_review: data.review_date,
          responsable: 'Almacén',
          document: (
            <TouchableOpacity style={{ alignItems: 'center' }}>
              <PdfIcon />
            </TouchableOpacity>
          ),
          actions: (
            <div className='d-flex'>
              {/* <TouchableOpacity style={{ paddingHorizontal: 1 }}>
            <i className='bx bx-pencil bx-sm'></i>
          </TouchableOpacity> */}
              <TouchableOpacity
                onPress={() => {
                  removeResDoc(data.id)
                }}
                style={{ paddingHorizontal: 1 }}
              >
                <i className='bx bx-trash bx-sm'></i>
              </TouchableOpacity>
            </div>
          ),
        },
      ]
      // setDataTable(datatableall)
      getResource()
      // if(status === 200) setNewId(data.id)
      // navigate('/recursos')
      // setIsLoading(false)
      setFormDocAsoc({
        name: '',
        document_type_id: '',
        resource_id: '',
        file: null,
        period: '',
        review_date: '',
        responsible: '',
      })
    } catch (error) {
      if (__DEV__) {
        console.log(error)
      }
    }
    setIsLoading(false)
  }
  const deleteImageRes = async (id: any) => {
    await baseApi.delete(`${API_URL}/resource/${resourceId}/remove-image`, {
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      },
      data: { deleteImages: [id] },
    })
    getResource()
  }
  return (
    <Wrapper title='Alta de recursos' subtitle=''>
      <div className='page-wrapper'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-xl-12 mx-auto'>
              <hr />
              <div className='card'>
                <div className='card-body'>
                  <form className='row g-3'>
                    <div className='col-md-2 mb-3'>
                      <label className='form-label'>Fecha de alta Cime:</label>
                      <input
                        value={formatDateEng(new Date(form.cime_date))}
                        onChange={(value) =>
                          setForm({ ...form, cime_date: value.target.value })
                        }
                        type='date'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-3 mb-3'>
                      <label className='form-label'>Buscar Tipo:</label>
                      <div className=' w-full'>
                        <DropdownTreeSelect
                          texts={{ placeholder: 'Seleccionar' }}
                          keepTreeOnSearch
                          onChange={(v: any, a: any) => {
                            setForm({ ...form, cime: v.value })
                          }}
                          mode='radioSelect'
                          data={resourcesTypes}
                          className='w-full'
                        />
                      </div>
                    </div>
                    <div className='col-md-3 mb-3'>
                      <label className='form-label'>Código CIME:</label>
                      <Input
                        value={form.cime}
                        onChangeText={(value) =>
                          setForm({ ...form, cime: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-4 mb-3'>
                      <label className='form-label'>
                        Nombre de la maquina:
                      </label>
                      <Input
                        value={form.name}
                        onChangeText={(value) =>
                          setForm({ ...form, name: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-4 mb-3'>
                      <label className='form-label'>Modelo:</label>
                      <Input
                        value={form.model}
                        onChangeText={(value) =>
                          setForm({ ...form, model: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-4 mb-3'>
                      <label className='form-label'>Nº de Serie:</label>
                      <Input
                        value={form.serie}
                        onChangeText={(value) =>
                          setForm({ ...form, serie: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-4 mb-3'>
                      <label className='form-label'>Año de fabricación:</label>
                      <input
                        value={`${form.year}-01-01`}
                        onChange={(value) => {
                          setForm({
                            ...form,
                            year: new Date(value.target.value)
                              .getFullYear()
                              .toString(),
                          })
                        }}
                        type='date'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-2 mb-1'>
                      <label className='form-label'>Peso:</label>
                      <Input
                        value={form.weight}
                        onChangeText={(value) =>
                          setForm({ ...form, weight: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label htmlFor='center_id' className='form-label'>
                        Almacén:
                      </label>
                      <Select
                        nativeID='center_id'
                        onValueChange={(value) =>
                          setForm({ ...form, depot_id: value })
                        }
                        selectedValue={form.depot_id.toString()}
                      >
                        <Select.Item value='' label='Escoger...' />
                        {delegations.map((centers: any) => (
                          <Select.Item
                            key={centers.id}
                            value={centers.id.toString()}
                            label={centers.name}
                          />
                        ))}
                      </Select>
                    </div>

                    <div className='col-md-4 mb-1'>
                      <label className='form-label'>Fabricante:</label>
                      <Input
                        value={form.fabricator}
                        onChangeText={(value) =>
                          setForm({ ...form, fabricator: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-8 mb-3'>
                      <label className='form-label'>Observaciones:</label>
                      <Input
                        value={form.observations}
                        onChangeText={(value) =>
                          setForm({ ...form, observations: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-4 mb-3'>
                      <label className='form-label'>
                        Fecha de Adquisición:
                      </label>
                      <input
                        value={
                          form.acquisition_date
                            ? formatDateEng(new Date(form.acquisition_date))
                            : ''
                        }
                        onChange={(value) =>
                          setForm({
                            ...form,
                            acquisition_date: value.target.value,
                          })
                        }
                        type='date'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-4 mb-3'>
                      <label className='form-label'>Foto Principal:</label>
                      <div className='row g-3'>
                        {
                          imagesRes.map((image: any, index: number) => {
                            if (index === 0) {
                              return (
                                <div className='col-12'>
                                  <Image
                                    width={200}
                                    height={200}
                                    alt='Alternate Text'
                                    source={{ uri: image?.url }}
                                  />
                                </div>
                              )
                            }
                            return false
                          })
                        }
                        {
                          images.map((image: any, index: number) => {
                            if (index === 0) {
                              return (
                                <div className='col-12'>
                                  <Image
                                    width={200}
                                    height={200}
                                    source={{ uri: URL.createObjectURL(image) }}
                                  />
                                </div>
                              )
                            }
                            return false
                          })
                        }
                      </div>
                    </div>
                    <div className='col-md-8 mb-3'>
                      <label className='form-label'>
                        Cambiar Foto Principal:
                      </label>
                      <input
                        onChange={(event: any) => {
                          if (images.length < 4) {
                            setImages([...images, event.target.files[0]])
                          }
                        }}
                        type='file'
                        className='form-control'
                      />
                    </div>
                    <div className='col-md-8 mb-3'>
                      <label className='form-label'>
                        Cambiar Foto Secundarias:
                      </label>
                      <input
                        onChange={(event: any) => {
                          if (images.length < 4) {
                            setImagesSec([...imagesSec, event.target.files[0]])
                          }
                        }}
                        type='file'
                        className='form-control'
                      />
                    </div>
                    <div className='col-md-8 mb-3'>
                      <div className='col-md-12 mb-3 mt-2'>
                        <label className='form-label'>Fotos Secundarias:</label>
                        <div className='row g-3'>
                          {imagesResExt.map((image: any, index: number) => {
                            return (
                              <div className='col-4'>
                                <TouchableOpacity
                                  onPress={() => {
                                    const confirmm = confirm(
                                      '¿Está seguro de eliminar esta imagen?'
                                    )
                                    if (confirmm) {
                                      deleteImageRes(image.id)
                                    }
                                  }}
                                  style={{ top: 20, left: 5, zIndex: 1000 }}
                                >
                                  <i className='bx bx-trash text-danger bx-xs'></i>
                                </TouchableOpacity>

                                <Image
                                  width={200}
                                  height={200}
                                  alt='Alternate Text2'
                                  source={{ uri: image?.image.url }}
                                />
                              </div>
                            )
                          })}
                          {imagesSec.map((image: any, index: number) => {
                            return (
                              <div className='col-4'>
                                <Image
                                  width={200}
                                  height={200}
                                  alt='Alternate Text'
                                  source={{ uri: URL.createObjectURL(image) }}
                                />
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>

                    <div className='col-md-3 offset-9 mb-3'>
                      <Button
                        color={'success.400'}
                        isLoading={isLoading}
                        onPress={() => createCentro()}
                      >
                        Guardar
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
              <div className='card'>
                <div className='card-body'>
                  <div className='dropdown' style={{ width: '70%' }}>
                    <HStack alignItems='center' space={3}>
                      <VStack>
                        <Text
                          fontSize={24}
                          fontWeight={'bold'}
                          color='primary.500'
                          _dark={{
                            color: 'primary.500',
                          }}
                        >
                          Documentos Asociados
                        </Text>
                      </VStack>
                    </HStack>
                  </div>
                  <div className='row g-3'>
                    <div className='col-md-12'>
                      <Table data={dataTable} columns={columns} />
                    </div>
                    <hr></hr>
                    <div className='col-md-6'>
                      <div className='col-md-12 mb-3'>
                        <label className='form-label'>Nombre:</label>
                        <Input
                          isRequired
                          value={formDocAsoc.name}
                          isDisabled={!newId}
                          onChangeText={(value) =>
                            setFormDocAsoc({ ...formDocAsoc, name: value })
                          }
                          type='text'
                          className='form-control'
                          placeholder=''
                        />
                      </div>
                    </div>
                    <div className='col-md-3 mb-3'>
                      <label htmlFor='document_type_id' className='form-label'>
                        Tipo:
                      </label>
                      <Select
                        nativeID='document_type_id'
                        isDisabled={!newId}
                        onValueChange={(value) =>
                          setFormDocAsoc({
                            ...formDocAsoc,
                            document_type_id: value,
                          })
                        }
                        selectedValue={formDocAsoc.document_type_id.toString()}
                      >
                        <Select.Item value='' label='Escoger...' />

                        {documentType.map((centers: any) => (
                          <Select.Item
                            key={centers.id}
                            value={centers.id.toString()}
                            label={centers.name}
                          />
                        ))}
                      </Select>
                    </div>
                    <div className='col-md-3 mb-3'>
                      <label htmlFor='responsible' className='form-label'>
                        Responsable:
                      </label>
                      <Select
                        nativeID='responsible'
                        isDisabled={!newId}
                        onValueChange={(value) =>
                          setFormDocAsoc({ ...formDocAsoc, responsible: value })
                        }
                        selectedValue={formDocAsoc.responsible.toString()}
                      >
                        <Select.Item value='' label='Escoger...' />
                        <Select.Item key={'5'} value={'5'} label={'Almacén'} />
                        <Select.Item
                          key={'3'}
                          value={'3'}
                          label={'Coordinador'}
                        />
                      </Select>
                    </div>
                    <div className='col-md-4 mb-3'>
                      <label className='form-label'>Periodo en meses:</label>
                      <Input
                        value={formDocAsoc.period}
                        isDisabled={!newId}
                        onChangeText={(value) =>
                          setFormDocAsoc({ ...formDocAsoc, period: value })
                        }
                        type='text'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-4 mb-3'>
                      <label className='form-label'>
                        Fecha Último Revisión:
                      </label>
                      <input
                        disabled={!newId}
                        value={formDocAsoc.review_date}
                        onChange={(value) =>
                          setFormDocAsoc({
                            ...formDocAsoc,
                            review_date: value.target.value,
                          })
                        }
                        type='date'
                        className='form-control'
                        placeholder=''
                      />
                    </div>
                    <div className='col-md-4 mb-3'>
                      <label className='form-label'>Subir Documento:</label>
                      <input
                        disabled={!newId}
                        onChange={(event: any) => {
                          if (formDocAsoc.file) {
                            if (formDocAsoc.file.length < 2) {
                              setFormDocAsoc({
                                ...formDocAsoc,
                                file: event.target.files[0],
                              })
                            }
                          } else {
                            setFormDocAsoc({
                              ...formDocAsoc,
                              file: event.target.files[0],
                            })
                          }
                        }}
                        type='file'
                        className='form-control'
                      />
                    </div>
                    <div className='col-md-3 offset-9 mb-3'>
                      <Button
                        isDisabled={!newId}
                        color={'success.400'}
                        isLoading={isLoading}
                        onPress={() => createDoc()}
                      >
                        Añadir
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
function Table({ data, columns }: { data: any[]; columns: ColumnDef<any>[] }) {
  // const table = useReactTable({
  //   data,
  //   columns,
  //   // Pipeline
  //   getCoreRowModel: getCoreRowModel(),
  //   getFilteredRowModel: getFilteredRowModel(),
  //   getPaginationRowModel: getPaginationRowModel(),
  //   //
  //   debugTable: false,
  // })

  // useEffect(() => {
  //   table.setPageSize(5)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  return (
    <div className='p-2 bg-white table-responsive'>
      <table className='table  table-dark'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Tipo</th>
            <th>Nombre</th>
            <th>Periodo (meses)</th>
            <th>Fecha última revisión</th>
            <th>Fecha próxima revisión</th>
            <th>Responsable</th>
            <th>Documento</th>
            <th></th>
          </tr>
        </thead>
        <tbody className='table-light fst-normal'>
          {data.map((v: any, index: number) => {
            return (
              <tr className='fst-normal'>
                <td>{v.id}</td>
                <td>{v.type}</td>
                <td>{v.name}</td>
                <td>{v.period}</td>
                <td>{v.date_last_review}</td>
                <td>{v.date_next_review}</td>
                <td>{v.responsable}</td>
                <td>{v.document}</td>
                <td>{v.actions}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className='h-2' />
      <div className='flex flex-column justify-content-end w-100 text-end'></div>
    </div>
  )
}
export default EditarRecursos
