import axios from "axios";
import {
	Text,
	Input,
	Select,
	Modal as Modals,
	FormControl,
	ScrollView,
  Image,
} from "native-base";
import { Avatar, Box, Button, HStack, VStack } from "native-base";
import { useEffect, useRef, useState } from "react";
import Wrapper from "../../components/Wrapper";
import { API_URL } from "../../utils/env";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { baseApi } from "../../utils/api";
import { TouchableOpacity } from "react-native";
import { UsuariosModal } from "../../components/UsuariosModal/UsuariosModal"
import Modal from "../../components/Modal/Modal"

function EditarCentros() {
	const [form, setForm] = useState({
		name: "",
		phone: "",
		address: "",
		delegation_id: "5",
		province_id: "",
	});
	  const params = useParams()
  const [newId, setNewId] = useState<any>(params.id)
	const [ data, setData] = useState(null);
	const [provinces, setProvinces] = useState([]);
	const [delegations, setDelegations] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [image, setImage] = useState<any>(null);
	const [userList, setUserList] = useState<any>([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [modalType, setModalType] = useState("");
			const [urlImage, setUrlImage] = useState<any>("/Bitmap.png");
	const [imageModalVisible, setImageModalVisible] = useState(false);

	const [usuarios, setUsuarios] = useState<any>({
		coord_centro: [],
		docentes: [],
		usuarios_almacen: [],
	});
	const initialRef = useRef(null);
	const finalRef = useRef(null);
	const navigate = useNavigate();
	const getCenterData = async () => {
		try {
			const { data } = await baseApi.get(`${API_URL}/center/${newId}`,{
			headers: {
					Authorization: "Bearer " + sessionStorage.getItem("token"),
				}
				});
			setData(data);
			setForm(data);
			setUsuarios({coord_centro: data.userCenter.filter((u: any) => u.role_id === 3).map((u: any) => {
        return {...u.user, rel_id: u.id}
      }), docentes: data.userCenter.filter((u: any) => u.role_id === 4).map((u: any) => {
        return {...u.user, rel_id: u.id}
      }), usuarios_almacen: []});
		} catch (error) {
			if(__DEV__){
				console.log(error);
			}
		}
	};
	const getUsuarios = async () => {
		try {
			const { data } = await baseApi.get(`${API_URL}/user/all`);
			setUserList(data);
		} catch (error) {
			if(__DEV__){
				console.log(error);
			}
		}
	};
	const getProvinces = async () => {
		setIsLoading(true);

		try {
			const { data } = await baseApi.get(`${API_URL}/province/all`);
			setProvinces(data);
		} catch (error) {
			if(__DEV__){
				console.log(error);
			}
		}
		setIsLoading(false);
	};
	const getDelegations = async () => {
		setIsLoading(true);

		try {
			const { data } = await baseApi.get(`${API_URL}/delegation/all`,{
				headers: {
					Authorization: "Bearer " + sessionStorage.getItem("token"),
				},
			});
			setDelegations(data);
		} catch (error) {
			if(__DEV__){
				console.log(error);
			}
		}
		setIsLoading(false);
	};
	useEffect(() => {
		getCenterData();
		getProvinces();
		getDelegations();
		getUsuarios();
	}, []);
	const setUserRole = async (user: any, role: string) => {
		setIsLoading(true);
		try {
			switch (role) {
				case "coord_centro":
					if(usuarios.coord_centro.findIndex((u: any) => u.id === user.id) === -1){
						setUsuarios({
							...usuarios,
							coord_centro: [...usuarios.coord_centro, user],
						});
					}
					
					break;
				case "docentes":
					if(usuarios.docentes.findIndex((u: any) => u.id === user.id) === -1){
						setUsuarios({
							...usuarios,
							docentes: [...usuarios.docentes, user],
						});
					}
					break;
			}
			setModalVisible(false);
		} catch (error) {
			if(__DEV__){
				console.log(error);
			}
		}
		setIsLoading(false);
	};
	// function modalUsuarios() {
	// 	return (
	// 		<>
	// 			<Modal
	// 				isOpen={modalVisible}
	// 				onClose={() => setModalVisible(false)}
	// 				initialFocusRef={initialRef}
	// 				finalFocusRef={finalRef}
	// 			>
	// 				<Modal.Content>
	// 					<Modal.CloseButton />
	// 					<Modal.Header>Seleccione Usuario</Modal.Header>
	// 					<Modal.Body>
	// 						<ScrollView style={{ maxHeight: "500px" }}>
	// 							{userList.map((user: any) => {
	// 								if (user.id === 1) return false;
	// 								return (
	// 									<TouchableOpacity
	// 										onPress={() => {
	// 											setUserRole(user, modalType);
	// 										}}
	// 									>
	// 										<HStack
	// 											mb={2}
	// 											backgroundColor={"#F5F7FA"}
	// 											px={1}
	// 											borderRadius={8}
	// 											borderColor={"primary.500"}
	// 											borderWidth={1}
	// 										>
	// 											<HStack
	// 												width={"100%"}
	// 												my={2}
	// 												alignItems="center"
	// 												space={3}
	// 											>
	// 												<Avatar
	// 													size={"sm"}
	// 													bg="green.500"
	// 													source={{
	// 														uri: user.image ? user.image.url : "",
	// 													}}
	// 												>
	// 													USER
	// 												</Avatar>
	// 												<Text
	// 													width={"100%"}
	// 													fontSize={18}
	// 													color="coolGray.600"
	// 													_dark={{
	// 														color: "warmGray.200",
	// 													}}
	// 												>
	// 													{user.username} {user.name}
	// 												</Text>
	// 											</HStack>
	// 										</HStack>
	// 									</TouchableOpacity>
	// 								);
	// 							})}
	// 						</ScrollView>
	// 					</Modal.Body>
	// 					{/* <Modal.Footer>
	// 						<Button.Group space={2}>
	// 							<Button
	// 								variant="ghost"
	// 								colorScheme="blueGray"
	// 								onPress={() => {
	// 									setModalVisible(false);
	// 								}}
	// 							>
	// 								Cancelar
	// 							</Button>
	// 							<Button
	// 								onPress={() => {
	// 									setModalVisible(false);
	// 								}}
	// 							>
	// 								Seleccionar
	// 							</Button>
	// 						</Button.Group>
	// 					</Modal.Footer> */}
	// 				</Modal.Content>
	// 			</Modal>
	// 		</>
	// 	);
	// }

	const createDelegacion = async () => {
		try {
			setIsLoading(true);
			var newDelegacion = new FormData();
			newDelegacion.append("name", form.name);
			newDelegacion.append("phone", form.phone);
			newDelegacion.append("address", form.address);
			newDelegacion.append("province_id", form.province_id);
			newDelegacion.append("delegation_id", "5");
      if(image){
      	newDelegacion.append("image", image!, image ? image.name : "");
      }

			await baseApi.put(
				`${API_URL}/center/update/${params.id}`,
				newDelegacion,
				{
					headers: {
						"Content-Type": "multipart/form-data",

						Authorization: "Bearer " + sessionStorage.getItem("token"),
					},
				}
			);
			// console.log(data.id);
			const newIdCentro = params.id;
			usuarios.coord_centro.filter((u:any) => !(data as any).userCenter.some((u2:any) => u2.user_id === u.id)).map(async (u: any) => {
				await baseApi.post(
					`${API_URL}/user/add/center`,
					{
						"user_id": u.id,
						"center_id": newIdCentro,
						"role_id": 3
					},
					{
						headers: {	
							Authorization: "Bearer " + sessionStorage.getItem("token"),
						},
					}
				);
			});
			usuarios.docentes.filter((u:any) => !(data as any).userCenter.some((u2:any) => u2.user_id === u.id)).map(async (u: any) => {
				await baseApi.post(
					`${API_URL}/user/add/center`,
					{
						"user_id": u.id,
						"center_id": newIdCentro,
						"role_id": 4
					},
					{
						headers: {	
							Authorization: "Bearer " + sessionStorage.getItem("token"),
						},
					}
				);
			});
			navigate("/centros");
		} catch (error) {
			if(__DEV__){
				console.log(error);
			}
		}
		setIsLoading(false);
	};
  	const removeUserCenter = async (id:any) => {
		try {
			const { data } = await baseApi.delete(`${API_URL}/user/remove/center/${id}`,{
				headers: {
					'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
				}
			});
		} catch (error) {
			if(__DEV__){
				console.log(error);
			}
		}
	}
  if(!data) return (<></>)  
	return (
		<Wrapper title="Editar Centros" subtitle="">
			{/* {modalUsuarios()} */}
			<UsuariosModal modalVisible={modalVisible} setModalVisible={setModalVisible} setUserRole={setUserRole} modalType={modalType} finalRef={finalRef} initialRef={initialRef}/>
					<Modal modalVisible={imageModalVisible} setModalVisible={setImageModalVisible} children={(
				<Image source={{uri: urlImage}} width={'400px'} height={'400px'} />
			)} title="Ver Imagen" childrenFooter={[]}/>
			<div className="page-wrapper">
				<div className="page-content">
					<div className="row">
						<div className="col-xl-12 mx-auto">
							<hr />
							<div className="card">
								<div className="card-body">
									<form className="row g-3">
										<div className="col-md-4 mb-3">
											<label className="form-label">Centro de Formación:</label>
											<Input
												value={form.name}
												onChangeText={(value) =>
													setForm({ ...form, name: value })
												}
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-4 mb-3">
											<label className="form-label">Telefono:</label>
											<Input
												value={form.phone}
												onChangeText={(value) =>
													setForm({ ...form, phone: value })
												}
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-4 mb-3">
											<label htmlFor="provincia" className="form-label">
												Provincia
											</label>
											<Select
												nativeID="provincia"
												onValueChange={(value) =>
													setForm({ ...form, province_id: value })
												}
												selectedValue={form.province_id.toString()}
											>
												<Select.Item value="" label="Escoger..." />
												{provinces.map((province: any) => (
													<Select.Item
														key={province.id}
														value={province.id.toString()}
														label={province.name}
													/>
												))}
											</Select>
										</div>
										<div className="col-md-12 mb-3">
											<label className="form-label">Dirección:</label>
											<Input
												value={form.address}
												onChangeText={(value) =>
													setForm({ ...form, address: value })
												}
												type="text"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-12 mb-3">
											<label className="form-label">Foto del Centro:</label>
											<input
												onChange={(event: any) =>
													setImage(event.target.files[0])
												}
												type="file"
												className="form-control"
												placeholder=""
											/>
										</div>
										<div className="col-md-12 mb-3">
											<label className="form-label">Foto (anterior):</label>
                        <div onClick={()=> {
													setUrlImage(!data ? "/Bitmap.png" :(data as any)?.image.url)
													setImageModalVisible(true)
												
												}}>
													<Image source={{uri: !data ? "/Bitmap.png" : (data as any)?.image.url}} width={200} height={200} alt="Logo Centro"/>
												</div>
										</div>
										{/* <div className="col-md-6 mb-3">
											<label htmlFor="delegations" className="form-label">
												Delegación:
											</label>
											<Select
												nativeID="delegations"
												onValueChange={(value) =>
													setForm({ ...form, delegation_id: value })
												}
												selectedValue={form.delegation_id.toString()}
											>
												<Select.Item value="" label="Escoger..." />
												{delegations.map((province: any) => (
													<Select.Item
														key={province.id}
														value={province.id.toString()}
														label={province.name}
													/>
												))}
											</Select>
										</div> */}
										{/* <div className="col-md-6 mb-3">
											<label htmlFor="estado" className="form-label">
												Almacen a Escoger
											</label>
											<select id="estado" className="form-select">
												<option selected={false}>Escoger...</option>
												<option>Activo</option>
												<option>No Activo</option>
											</select>
										</div> */}
										<div className="col-md-6 mb-3">
											<HStack space={2} justifyContent={"space-between"}>
												<VStack
													textAlign={"center"}
													justifyContent={"center"}
													justifyItems={"center"}
												>
													<h5 className="form-label m-0">
														Coordinador del centro
													</h5>
												</VStack>
												<Box justifyContent={"flex-end"}>
													<Button
														onPress={() => {
															setModalVisible(true);
															setModalType("coord_centro");
														}}
														justifyContent={"flex-end"}
														variant={"ghost"}
													>
														<i className="bx font-20 bx-plus-circle"></i>
													</Button>
												</Box>
											</HStack>

											<VStack width={"100%"}>
												{usuarios.coord_centro.map((user: any) => (
													<HStack
														mb={2}
														backgroundColor={"#F5F7FA"}
														px={1}
														borderRadius={8}
														borderColor={"primary.500"}
														borderWidth={1}
													>
														<HStack
															width={"100%"}
															my={2}
															alignItems="center"
															space={3}
														>
															<Avatar
																size={"sm"}
																bg="green.500"
																source={{
																	uri: user.image ? user.image.url : "",
																}}
															>
																{user.username?.charAt(0)}
															</Avatar>
															<Text
																width={"100%"}
																fontSize={18}
																color="coolGray.600"
																_dark={{
																	color: "warmGray.200",
																}}
															>
																{user.username} {user.name}
															</Text>
															<Button onPress={()=> {
																setUsuarios({
																	...usuarios,
																	coord_centro: usuarios.coord_centro.filter((u: any) => u.id !== user.id)
																})
                                removeUserCenter(user.rel_id)
															}} variant={"ghost"}>
																<i className="bx bxs-trash"></i>
															</Button>
														</HStack>
													</HStack>
												))}
											</VStack>
										</div>
										<div className="col-md-6 mb-3">
											<HStack space={2} justifyContent={"space-between"}>
												<VStack
													textAlign={"center"}
													justifyContent={"center"}
													justifyItems={"center"}
												>
													<h5 className="form-label">Docente</h5>
												</VStack>
												<Box justifyContent={"flex-end"}>
													<Button
														onPress={() => {
															setModalType("docentes");
															setModalVisible(true);
														}}
														justifyContent={"flex-end"}
														variant={"ghost"}
													>
														<i className="bx font-20 bx-plus-circle"></i>
													</Button>
												</Box>
											</HStack>

											<VStack width={"100%"}>
											{usuarios.docentes.map((user: any) => (
													<HStack
														mb={2}
														backgroundColor={"#F5F7FA"}
														px={1}
														borderRadius={8}
														borderColor={"primary.500"}
														borderWidth={1}
													>
														<HStack
															width={"100%"}
															my={2}
															alignItems="center"
															space={3}
														>
															<Avatar
																size={"sm"}
																bg="green.500"
																source={{
																	uri: user.image ? user.image.url : "",
																}}
															>
																{user.username?.charAt(0)}
															</Avatar>
															<Text
																width={"100%"}
																fontSize={18}
																color="coolGray.600"
																_dark={{
																	color: "warmGray.200",
																}}
															>
																{user.username} {user.name}
															</Text>
															<Button onPress={()=> {
																setUsuarios({
																	...usuarios,
																	docentes: usuarios.docentes.filter((u: any) => u.id !== user.id)
																})
                                removeUserCenter(user.rel_id)
															}} variant={"ghost"}>
																<i className="bx bxs-trash"></i>
															</Button>
														</HStack>
													</HStack>
												))}
											</VStack>
										</div>
										<div className="col-md-12 mb-3">
											<Button
												isLoading={isLoading}
												onPress={() => createDelegacion()}
											>
												Editar Centro
											</Button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
}
export default EditarCentros;
